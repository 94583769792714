<template>
  <b-container fluid>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h4 class="mr-2">Inventory Summary</h4>
      </div>
      <div class="d-flex align-items-center">
        <b-button
          variant="dark"
          class="mr-2 my-1"
          :to="{ path: '/inventory/create' }"
        >
          <feather-icon icon="PlusIcon" size="16" class="mr-25"></feather-icon>
          Add a New Product
        </b-button>
        <b-button variant="primary" v-b-modal.record-stock-modal>
          <feather-icon icon="EditIcon" size="16"></feather-icon>
          Record Stock
        </b-button>
      </div>
    </div>
    <b-row>
      <b-col cols="6">
        <b-card bg-variant="primary" text-variant="white">
          <b-row>
            <b-col>
              <h5 class="text-white">All Products</h5>
            </b-col>
            <b-col>
              <h5 class="text-white">Lowstock alert</h5>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <p>
                <strong>{{ totalProducts }}</strong>
              </p>
            </b-col>
            <b-col>
              <p>
                <strong>{{ lowStockProducts }}</strong>
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card>
          <b-row>
            <b-col>
              <h5>In</h5>
            </b-col>
            <b-col>
              <h5>Out</h5>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <p>
                <strong>{{ totalProducts }}</strong>
              </p>
            </b-col>
            <b-col>
              <p>
                <strong>{{ lowStockProducts }}</strong>
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <MyTable
        :columns="columns"
        :rows="rows"
        :isLoading="isLoading"
        :checkboxEnabled="true"
        @deleteItems="deleteItems"
        :inventory="true"
      >
        <template v-slot="{ props }">
          <div v-if="props.column.field === 'image'">
            <div
              v-if="
                props.row.product_images && props.row.product_images.length > 0
              "
            >
              <img
                :src="props.row.product_images[0].image_path"
                class="img-thumbnail"
                alt="Product Image"
                style="width: 160px; height: 130px"
              />
            </div>
            <div v-else>No image available</div>
          </div>
          <div
            v-else-if="props.column.field === 'action'"
            class="d-flex justify-content-center"
          >
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'inventory-product-detail',
                  params: { id: props.row.id },
                }"
              >
                <span>Details</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="{
                  name: 'inventory-edit-product',
                  params: { id: props.row.id },
                }"
              >
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteItem(props.row)">
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </MyTable>
    </b-card>
    <b-modal
      id="record-stock-modal"
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <b-form>
        <!-- Radio Buttons -->
        <b-form-group label="Transaction Type">
          <b-form-radio-group
            v-model="transactionType"
            name="transactionType"
            buttons
            button-variant="primary"
            class="w-100"
          >
            <b-form-radio value="in" class="w-50">In</b-form-radio>
            <b-form-radio value="out" class="w-50">Out</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <!-- Date Added -->
        <b-form-group label="Date Added">
          <flat-pickr
            v-model="dateAdded"
            class="form-control"
            placeholder="Select Date"
          ></flat-pickr>
        </b-form-group>

        <!-- Order ID -->
        <b-form-group label="Order ID">
          <b-form-input
            v-model="orderId"
            placeholder="Enter Order ID"
          ></b-form-input>
        </b-form-group>

        <!-- Product ID -->
        <b-form-group v-if="transactionType === 'out'" label="Product ID">
          <v-select
            v-model="productId"
            :options="
              rows.map((row) => ({ label: row.id, value: row.id }))
            "
            label="label"
            placeholder="Select a Product"
            class="v-select"
          />
        </b-form-group>

        <!-- Quantity -->
        <b-form-group label="Quantity">
          <b-form-input
            v-model="quantity"
            type="number"
            placeholder="Enter Quantity"
          ></b-form-input>
        </b-form-group>

        <!-- Warehouse Location -->
        <b-form-group label="Warehouse Location">
          <v-select
            v-model="warehouseLocation"
            :options="branches"
            label="name"
            placeholder="Select a Warehouse Location"
            class="v-select"
            multiple
          />
        </b-form-group>

        <!-- Remarks -->
        <b-form-group label="Remarks">
          <b-form-textarea
            v-model="remarks"
            placeholder="Enter Remarks"
          ></b-form-textarea>
        </b-form-group>

        <!-- Buttons -->
        <b-row class="justify-content-center">
          <b-col cols="auto">
            <b-button
              variant="secondary"
              @click="$bvModal.hide('record-stock-modal')"
              >Cancel</b-button
            >
          </b-col>
          <b-col cols="auto">
            <b-button variant="primary" @click="submitStock">Submit</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FeatherIcon,
  },

  data() {
    return {
      transactionType: "in", // Default value
      dateAdded: null,
      orderId: "",
      quantity: "",
      warehouseLocation: "",
      remarks: "",
      columns: [
        {
          label: "Product Image",
          field: "image",
          tdClass: "text-center align-middle",
        },
        {
          label: "Product Name",
          field: "product_name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Category",
          field: "product_category",
          tdClass: "text-center align-middle",
        },
        {
          label: "Selling Price",
          field: "selling_price",
          tdClass: "text-center align-middle",
        },
        {
          label: "In-Stock(Carton)",
          field: "remaining_quantity",
          tdClass: "text-center align-middle",
        },
        {
          label: "Total Value",
          field: "total_value",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
      required,
      branches: [],
    };
  },

  created() {
    this.fetchProducts();
    this.fetchBranches();
  },

  computed: {
    totalProducts() {
      return this.rows.length;
    },
    lowStockProducts() {
      return this.rows.filter((product) => product.remaining_quantity < 11)
        .length;
    },
  },

  methods: {
    async fetchProducts() {
      this.isLoading = true;
      try {
        const response = await this.$http.get(
          "inventory/admin/products?page=1"
        );
        if (response.status === 200 && response.data.data?.length > 0) {
          this.rows = response.data.data.map((row) => ({
            ...row,
            total_value: row.selling_price * row.remaining_quantity,
          }));
        } else {
          this.rows = [];
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        this.rows = [];
      } finally {
        this.isLoading = false;
      }
    },

    submitStock() {
      // Your submit logic here
    },

    async deleteItem(row) {
      try {
        const response = await this.$http.delete(
          `inventory/admin/products/${row.id}`
        );
        if (response.status === 200) {
          // Handle successful deletion
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "CheckIcon",
              variant: "success",
              text: "Deleted successfully",
            },
          });
          // Fetch updated products list
          this.fetchProducts();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "XIcon",
              variant: "warning",
              text: "Delete failed",
            },
          });
        }
      } catch (error) {
        console.error("Error deleting item:", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error,
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }
    },
    async fetchBranches() {
      try {
        const response = await this.$http.get(
          "inventory/admin/get_all_warehouses"
        );
        if (response.status === 200 && response.data.data?.length > 0) {
          this.branches = response.data.data;
        } else {
          this.branches = [];
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
        this.branches = [];
      }
    },
    async deleteItems(selectedIds) {
      try {
        const ids = selectedIds.join(",");
        const response = await this.$http.get(
          `/inventory/admin/batch_remove_products/${ids}`
        );
        if (response.status === 200) {
          // Handle successful deletion
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "CheckIcon",
              variant: "success",
              text: "Deleted successfully",
            },
          });
          // Fetch updated products list
          this.fetchProducts();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "XIcon",
              variant: "warning",
              text: "Delete failed",
            },
          });
        }
      } catch (error) {
        console.error("Error deleting items:", error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
